<template>
  <validation-observer ref="simpleRules">
    <b-card title="Change Password">
      <b-form>
        <b-row>
          <b-col md="3"></b-col>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="Password" label-for="a-password">
                  <validation-provider #default="{ errors }" name="Password" vid="Password"
                    rules="required|min:8|password">
                    <b-form-input id="a-password" v-model="newPassword" :state="errors.length > 0 ? false : null"
                      type="password" placeholder="Password" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <!-- confirm password -->
                <b-form-group label="Confirm Password" label-for="ac-password">
                  <validation-provider #default="{ errors }" name="Confirm Password"
                    rules="required|confirmed:Password">

                    <b-form-input id="ac-password" v-model="confirmPassword" :state="errors.length > 0 ? false : null"
                      type="password" placeholder="Confirm Password" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group class="mt-2">
                  <b-form-checkbox v-model="needChangePassword">Need Change Password</b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="12" class="text-center">
                <!-- update button -->
                <b-button class="mt-2 mr-2" variant="primary" type="submit" @click.prevent="validationForm">
                  Update
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3"></b-col>

        </b-row>
      </b-form>
    </b-card>
  </validation-observer>

</template>
  
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BFormCheckbox
} from 'bootstrap-vue'
import {
  required, min, confirmed,
} from '@validations'
import api from '@/store/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard, BFormCheckbox
  },
  data() {
    return {
      confirmPassword: '',
      newPassword: '',
      required,
      min,
      confirmed,
      codePlaceholderArgument,
      needChangePassword,
    }
  },
  props: {
    userId: Number
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          let self = this;
          var axios = require('axios');

          var data = JSON.stringify({
            "needChengePassword": self.needChangePassword,
            "newPassword": self.newPassword,
            "userId": self.userId,
          });
          // alert(data)
          axios(api.getApi('put', '/users/updateUserPassword', data))
            .then(function (response) {
              //console.log(JSON.stringify(response.data));
              self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Password Updated Successfully!`,
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
              self.reset();
            })
            .catch(function (error) {
              console.log(error);
            });



        }
      })
    },
    reset() {
      let self = this;
      self.userId = 0;
      self.newPassword = '';
      self.confirmPassword = '';
      self.needChangePassword = false;
    }
  },
}
</script>
