<template>
    <b-card title="Add New">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="3">
                        <b-form-group label="Customer Prefix">
                            <v-select v-model="customerPrefix" :options="customerPrefixOption" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Customer Name *">
                            <validation-provider #default="{ errors }" name="Customer Name" rules="required">
                                <b-form-input v-model="customerName" :state="errors.length > 0 ? false : null"
                                    placeholder="Please Enter Customer Name" />
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Mobile No *">
                            <validation-provider #default="{ errors }" name="Mobile No"
                                rules="required|regex:^[6789]\d{9}$">
                                <b-form-input v-model="mobileNo" :state="errors.length > 0 ? false : null"
                                    placeholder="Please Enter Mobile No" />
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Email">
                            <b-form-input v-model="emailId" placeholder="Please Enter Email" />
                        </b-form-group>
                    </b-col>
                    <hr>
                    <b-col md="12">
                        <b-form-group>
                            <b-form-checkbox v-model="fillMoreDetails">Add More Information</b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-row v-if="fillMoreDetails">
                        <b-col md="6">
                            <b-form-group label="Address">
                                <b-form-textarea v-model="address" rows="2" placeholder="Please Enter Address" />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Area Location">
                                <b-form-textarea v-model="areaLocation" rows="2" placeholder="Please Enter Area Location" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="State">
                                <b-form-input v-model="state" placeholder="Please Enter State" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="PinCode">
                                <b-form-input v-model="pinCode" placeholder="Please Enter Pin Code" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Customer Preference">
                                <v-select v-model="customerPreference" label="name" placeholder="Select Preference"
                                    :options="customerPreferenceOption" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Default Discount (%)">
                                <b-form-input v-model="defaultDiscountPercentage"
                                    @input="checkMaxDiscountRestricationPercentage"
                                    placeholder="Please Enter Discount (%)" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Communication Preference">
                                <v-select v-model="communicationPreference" label="name"
                                    placeholder="Select Communication Preference"
                                    :options="communicationPreferenceOption" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Customer GSTIN">
                                <b-form-input v-model="gstin" placeholder="Please Enter Customer GSTIN" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Membership Id">
                                <b-form-input v-model="membershipId" placeholder="Please Enter Membership Id" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Barcode">
                                <b-form-input v-model="barcode" placeholder="Please Enter Barcode" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Birthday">
                                <flat-pickr v-model="birthday" class="form-control" name="date"
                                    placeholder="Please Enter Birthday" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Anniversary Date">
                                <flat-pickr v-model="anniversary" class="form-control" name="date"
                                    placeholder="Please Enter Anniversary Date" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Remark">
                                <b-form-input v-model="remarks" placeholder="Please Enter Remark" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Home Delivery">
                                <v-select v-model="homeDelivery" label="name" placeholder="Select Home Delivery"
                                    :options="homeDeliveryOption" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Marketing Source">
                                <b-form-input v-model="marketingSource" placeholder="Please Enter Marketing Source" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Alternative Phone">
                                <b-form-input v-model="alternetPhone" placeholder="Please Enter Phone" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Facebook ID">
                                <b-form-input v-model="facebookId" placeholder="Please Enter Facebook ID" />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Registration Source">
                                <v-select v-model="registrationSource" label="name" placeholder="Enter Registration Source"
                                    :options="registrationSourceOption" />
                            </b-form-group>
                        </b-col>
                        <!-- <b-col md="3">
                        <b-form-group label="Password">
                            <b-form-input v-model="password" type="password" placeholder="Please Enter Password" />
                        </b-form-group>
                        </b-col> -->
                        <b-col md="6">
                            <b-form-group label="Message on Search Customer">
                                <b-form-textarea v-model="messageOnSearchCustomer" rows="2"
                                    placeholder="Please Enter Message on Search Customer" />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Message On Garment Delivery">
                                <b-form-textarea v-model="messageOnGarmentDelivery" rows="2"
                                    placeholder="Please Enter Message On Garment Delivery" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-col cols="6">
                        <b-form-group>
                            <b-form-checkbox v-model="isEnebled">Is Active</b-form-checkbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="text-center">
                        <b-button variant="primary" class="mt-1 mr-1" type="submit" @click.prevent="validationForm">
                            <feather-icon icon="UserPlusIcon" class="mr-50" /> Add
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard, BCol, BRow, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import api from '@/store/api'
import util from '@/store/utils'

export default {
    components: {
        ValidationProvider, ValidationObserver, BFormCheckbox,
        BCard,
        BCol,
        BRow,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        vSelect,
        flatPickr
    },
    data() {
        return {
            customerPrefixOption: [],
            customerPrefix: 'Mr',
            customerName: '',
            mobileNo: '',
            emailId: '',
            address: '',
            areaLocation: '',
            state: '',
            pinCode: '',
            customerPreferenceOption: [],
            customerPreference: null,
            defaultDiscountPercentage: 0,
            communicationPreferenceOption: util.communicationPreferenceOptions,
            communicationPreference: null,
            gstin: '',
            membershipId: '',
            barcode: '',
            birthday: '',
            anniversary: '',
            remarks: '',
            homeDeliveryOption: util.homeDeliveryOptions(),
            homeDelivery: util.homeDelivery(),
            enabelHomeDelevery: false,
            marketingSource: '',
            alternetPhone: '',
            facebookId: '',
            registrationSourceOption: [],
            registrationSource: null,
            //password: '',
            messageOnSearchCustomer: '',
            messageOnGarmentDelivery: '',
            isEnebled: true,
            fillMoreDetails: false,
            required,
            maxDiscountRestricationPersentage: this.$store.state.maxDiscountRestricationPersentage
        }
    },
    methods: {
        getNamePrefixList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getNamePrefixList', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.customerPrefixOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getCustomerPreferences() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/customerPreferences', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.customerPreferenceOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // getCommunicationPreference() {
        //     let self = this;

        //     var axios = require('axios');
        //     var data = '';
        //     axios(api.getApi('get', '/masters/communicationPreferences', data))
        //         .then(function (response) {
        //             //console.log(JSON.stringify(response.data));

        //             self.communicationPreferenceOption = response.data;
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // },
        validationForm() {
            let self = this;
            self.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // if(self.password == ''){
                    //     self.password=self.mobileNo;
                    // }  
                    if (self.homeDelivery == 'Yes') {
                        self.enabelHomeDelevery = true;
                    } else if (self.homeDelivery == 'No') {
                        self.enabelHomeDelevery = false;
                    }

                    if (self.communicationPreference == null) {
                        self.communicationPreference = null
                    } else {
                        self.communicationPreference = { 'id': self.communicationPreference.id, 'name': self.communicationPreference.name };
                    }

                    if (self.customerPreference == null) {
                        self.customerPreference = null
                    } else {
                        self.customerPreference = { 'id': self.customerPreference.id, 'name': self.customerPreference.name };
                    }

                    if (self.registrationSource == null) {
                        self.registrationSource = 0
                    } else {
                        self.registrationSource = self.registrationSource.id;
                    }

                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            // "userName":self.mobileNo,
                            // "password":self.mobileNo,
                            "namePrefix": self.customerPrefix,
                            "name": self.customerName,
                            "mobileNo": self.mobileNo,
                            "emailId": self.emailId,
                            "address": self.address,
                            "areaLocation": self.areaLocation,
                            "state": self.state,
                            "pinCode": self.pinCode,
                            "customerPreference": self.customerPreference,
                            "defaultDiscountPercentage": self.defaultDiscountPercentage,
                            "communicationPrefrence": self.communicationPreference,
                            "gstin": self.gstin,
                            "membershipId": self.membershipId,
                            "barcode": self.barcode,
                            "birthday": self.birthday,
                            "anniversary": self.anniversary,
                            "remarks": self.remarks,
                            "enabelHomeDelevery": self.enabelHomeDelevery,
                            "marketingSource": self.marketingSource,
                            "alternetPhone": self.alternetPhone,
                            "facebookId": self.facebookId,
                            "registrationSource": self.registrationSource,
                            "msgOnSearchCustomer": self.messageOnSearchCustomer,
                            "msgOnGarmentDelevery": self.messageOnGarmentDelivery,
                            "isEnebled": self.isEnebled
                        }
                    );
                    axios(api.getApi('Post', '/customers', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));

                            self.$swal({
                                title: '',
                                text: "Customer Added Successfully!",
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            }).then(result => {
                                if (result.value) {
                                    self.$router.push({ name: 'customerDetail', params: { id: response.data.id } })
                                }
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                } else {
                    this.$swal({
                        title: '',
                        text: 'Please Fill Mandatory Fields!',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-secondary',
                        },
                        buttonsStyling: false,
                    })
                }
            })
        },
        checkMaxDiscountRestricationPercentage(text) {
            let self = this;
            var value = ''
            if (text > self.maxDiscountRestricationPersentage) {
                text = value;

                self.$swal({
                    title: '',
                    text: "Percentage Greater Than Max Discount Configuration Percentage ( " + this.maxDiscountRestricationPersentage + "% )!",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        self.defaultDiscountPercentage = 0;
                    }
                })
            }
        },
        getRegistrationSource() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getRegistrationSourceList', data))
                .then(function (response) {
                    let i = 0;
                    for (let index = 0; index < response.data.length; index++) {
                        i++;
                        self.registrationSourceOption.push({ 'id': i, 'name': response.data[index] })
                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
    },
    created() {
        this.getCustomerPreferences();
        this.getNamePrefixList();
        this.getRegistrationSource();
        // this.getCommunicationPreference();
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>